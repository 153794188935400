html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #fff;
}

.github-button {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  width: 100%;
}
